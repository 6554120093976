<template>
  <div>
    <a href="#" v-if="!date" @click.prevent="createDate()" class="flix-btn">{{
      $t("time.unlimited")
    }}</a>
    <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-5" v-else>
      <a href="#" class="flix-btn" @click.prevent="deleteDate()">
        <icon id="menu-close"
      /></a>
      <datePicker :time="date" :callback="setDate" />
    </div>
  </div>
</template>
<script>
import startDate from "./startDate";
export default {
  extends: startDate,
  data() {
    return {
      date: this.$store.state.business.unsaved.end
    };
  },
  methods: {
    createDate() {
      var d = new Date();
      d.setMonth(d.getMonth() + 1);
      d.setMinutes(0);
      this.date =
        d.getFullYear() +
        "-" +
        this.getNullsBefore(d.getMonth() + 1) +
        "-" +
        this.getNullsBefore(d.getDate()) +
        " " +
        this.getNullsBefore(d.getHours()) +
        ":" +
        this.getNullsBefore(d.getMinutes());
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.end = this.date;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
